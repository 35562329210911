import React, { useState } from 'react';

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '20px',
};

const inputStyle: React.CSSProperties = {
  flex: 1,
  width: '100%',
  minHeight: '200px'
};

const buttonStyle: React.CSSProperties = {
  backgroundColor: '#1e88e5',
  color: 'white',
  padding: '12px 20px',
  borderRadius: '5px',
  textDecoration: 'none',
  fontWeight: "bold",
  fontSize: '16px',
  border: 'none'
};

const CharacterCounter = () => {
  const [text, setText] = useState('');

  const handleClear = () => {
    setText('');
  };

  return (
    <div style={containerStyle}>
      <textarea
        style={inputStyle}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div>Character Count: {text.length}</div>
      <button style={buttonStyle} onClick={handleClear}>
        Clear
      </button>
    </div>
  );
};

export default CharacterCounter;