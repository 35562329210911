import Prism from "prism-react-renderer/prism";
import cLike from "refractor/lang/clike";
import kotlin from './prism/kotlin';
import dartLang from './prism/dart';
import java from './prism/java';
import swift from './prism/swift';
cLike(Prism);
dartLang(Prism);
kotlin(Prism);
java(Prism);
swift(Prism);