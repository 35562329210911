import React, { useEffect } from "react";

interface ArticleAdProps {
  debugMode?: boolean;
}

const ArticleAd = ({debugMode = false}: ArticleAdProps) => {
  useEffect(()=>{
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  },[]);

  return (
    <div>
    <ins className="adsbygoogle"
     style={{display: "block"}}
     data-adtest={debugMode ? "on" : "off"}
     data-ad-client="ca-pub-1355395029356317"
     data-ad-slot="6204461718"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
    </div>
  );
};

export default ArticleAd;