import React, { useState } from 'react';

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '20px',
};

const fieldStyle: React.CSSProperties = {
  flex: 1,
  width: '100%',
  padding: '10px',
  borderRadius: '5px',
  border: '1px solid #ccc',
  marginBottom: '10px',
  fontFamily: 'Arial, sans-serif',
};

const inputStyle: React.CSSProperties = {
  ...fieldStyle,
  minHeight: '200px'
};

const buttonStyle: React.CSSProperties = {
  backgroundColor: '#1e88e5',
  color: 'white',
  padding: '12px 20px',
  borderRadius: '5px',
  textDecoration: 'none',
  fontWeight: "bold",
  fontSize: '16px',
  border: 'none'
};

const errorStyle: React.CSSProperties = {
  borderColor: 'red',
  borderWidth: '3px',
  color: 'red',
};

const AppMetadata = () => {
  const [appName, setAppName] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [keywords, setKeywords] = useState('');

  const handleClear = () => {
    setAppName('');
    setSubtitle('');
    setDescription('');
    setKeywords('');
  };

  return (
    <div style={containerStyle}>
      <div>{appName.length}/30</div>
      <input
        style={appName.length > 30 ? { ...fieldStyle, ...errorStyle } : fieldStyle}
        value={appName}
        onChange={(e) => setAppName(e.target.value)}
        placeholder="App Name"
      />
      <div>{subtitle.length}/30</div>
      <input
        style={subtitle.length > 30 ? { ...fieldStyle, ...errorStyle } : fieldStyle}
        value={subtitle}
        onChange={(e) => setSubtitle(e.target.value)}
        placeholder="Subtitle"
      />
      <div>{description.length}/4000</div>
      <textarea
        style={description.length > 4000 ? { ...inputStyle, ...errorStyle } : inputStyle}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
      />
      <div>{keywords.length}/100</div>
      <input
        style={keywords.length > 100 ? { ...fieldStyle, ...errorStyle } : fieldStyle}
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        placeholder="Keywords"
      />
      <button style={buttonStyle} onClick={handleClear}>
        Clear All
      </button>
    </div>
  );
};

export default AppMetadata;