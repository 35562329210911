import React, { useState } from 'react';

const RemoveSpacesBox = () => {
  const [text, setText] = useState('');

  const handleRemoveSpaces = () => {
    setText(text.replace(/\s/g, ''));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '20px' }}>
      <textarea
        style={{ flex: 1, width: '100%', minHeight: '200px' }}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <button onClick={handleRemoveSpaces}>
        Remove All Spaces
      </button>
    </div>
  );
};

export default RemoveSpacesBox;