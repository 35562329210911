import React from 'react';

const containerStyle = {
  display: 'flex',
  padding: '20px',
  margin: '20px',
  border: '1px solid #ccc',
  borderRadius: '20px',
};

const descriptionStyle = {
  marginLeft: '10px',
};

const titleStyle: React.CSSProperties = {
  fontWeight: "bold",
  fontSize: '30px',
  color: '#1e88e5',
  textDecoration: 'none',
}

const buttonStyle: React.CSSProperties = {
  backgroundColor: '#1e88e5',
  color: 'white',
  padding: '12px 20px',
  borderRadius: '5px',
  textDecoration: 'none',
  fontWeight: "bold",
};

const AstroAd = ({ affiliateLink }) => {
  return (
    <div style={containerStyle}>
      <div style={descriptionStyle}>
        <a href={affiliateLink} target="_blank" rel="noopener noreferrer" style={titleStyle}>
          Astro(ASO Tool)
        </a>
        <p style={{ color: '#1e88e5', marginTop: '10px' }}>
          If you're looking to increase your app's visibility, Astro is the tool for you. You can track your app's keyword rankings for multiple countries all at the same time.
          I've been using it for a few months now and it's been a game-changer for me. I highly recommend it! 🚀
        </p>

        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
          <a style={buttonStyle} href="https://tryastro.app?aff=11RlE">
            Get Astro
          </a>
        </div>
        <div style={{ color: '#777', opacity: 0.6, marginTop: '20px', fontSize: '14px' }}>
          We earn a commission if you make a purchase, at no additional cost to you.
        </div>
      </div>
    </div>
  );
};

export default AstroAd;