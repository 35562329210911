import React, { useEffect } from "react";

interface InArticleAdProps {
  debugMode?: boolean;
}

const InArticleAd = ({debugMode = false}: InArticleAdProps) => {
  useEffect(()=>{
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  },[]);

  return (
    <div>
     <ins className="adsbygoogle"
     style={{display: "block", textAlign: "center"}}
     data-adtest={debugMode ? "on" : "off"}
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-1355395029356317"
     data-ad-slot="6509537871"></ins>
    </div>
  );
};

export default InArticleAd;